/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imgPath } from '../../../utils/constants';
import { TaskToCompleteSidebar, ConnectFitnessDeviceContainer, ConnectButton, RightContainer } from './Styles';

class ConnectFitnessDevice extends Component {

  render() {
    return (
      <TaskToCompleteSidebar bgColor={1}>
        <ConnectFitnessDeviceContainer>
          <img src={`${imgPath}/NewDashboardImages/mobile_icon.png`} alt="connect Device"/>
          <RightContainer connectDevice={1}>
            <div className="connectDevice">Connect your fitness device</div>
            <ConnectButton onClick={this.props.displayDeviceModal}>
              <span>Connect</span>
            </ConnectButton>
          </RightContainer>
        </ConnectFitnessDeviceContainer>
      </TaskToCompleteSidebar>
    );
  }
}

ConnectFitnessDevice.propTypes = {
  devices: PropTypes.array,
  revokeDevice: PropTypes.func,
  displayDeviceModal: PropTypes.func.isRequired
};

export default connect(null, null)(ConnectFitnessDevice);
